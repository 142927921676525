import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button';
import logo from '../assets/logo.svg';
import './Home.css';

const BOOK_URL = 'https://app.acuityscheduling.com/schedule.php?owner=18381267';

const Home = () => {

    function handleClickCta() {
        window.open(BOOK_URL, '_blank');
    }

    return (
        <Container fluid>
            <Row>
                <Col className="subway-col" xs={7} sm={5} md={3}>
                    <Image alt='57 West Dermatology subway inspired logo' className="subway-logo p-3" src={logo} fluid></Image>
                    <div className='cta px-3'>
                        <h4 className='text-start text-white'>Next stop,<br /> beautiful skin.</h4>
                        <div className='d-grid gap-2'>
                            <Button onClick={handleClickCta} className='mt-3' variant="outline-light">Book Now</Button>
                        </div>
                    </div>
                </Col>

                <Col className="mural-col" xs={5} sm={7} md={9}>
                    {/* Background image here */}
                </Col>
            </Row>
        </Container>
    );
}

export default Home;