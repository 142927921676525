import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Accordion from 'react-bootstrap/Accordion';
import './Services.css';

const Services = () => {
    return (
        <Container id='our-services' fluid className='services-container'>
            <Row>
                <Col xs={{ span: 12, order: 1 }} sm={12} md={{ span: 7, order: 0 }} className='services-col v-center-col'>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h1>Our Services</h1>
                        <p>We provide a variety of Medical and Cosmetic Dermatology services including:</p>

                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><strong>Medical Services</strong></Accordion.Header>
                                <Accordion.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className='service-item'>Skin cancers - early detection, prevention and treatment</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Eczema</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Psoriasis</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Poison ivy and allergic reactions</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Warts</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Moles and skin tags</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Skin infections</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Skin pigment disorders</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Cysts</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Hyperhidrosis treatment</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Convenient in-office dispensing of customized prescription compounds - specifically designed for your skin and hair needs</ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><strong>Cosmetic Services</strong></Accordion.Header>
                                <Accordion.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item className='service-item'>Botox, Dysport, Xeomin and Daxxify injections</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Restylane, Lyft, Juvederm and Voluma fillers</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Ultherapy, to non-invasively lift the eyebrow, neck, under-chin and décolletage</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Kybella injectable double chin treatment</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Laser treatments for leg veins</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>NewSurg KTP and Varilite diode laser treatments for broken blood vessels and age spots</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>DermaSweep microdermabrasion treatments for face and body</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Scar treatments</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Facial and body chemical peels</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Microneedling using the Collagen P.I.N.</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Pixel8-RF Radio Frequency Microneedling</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Body contouring with Radiesse and Sculptra</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>Platelet-rich fibrin injections (PRF) for facial rejuvenation</ListGroup.Item>
                                        <ListGroup.Item className='service-item'>57 West Dermatology now uses the state of the art AccuVein visualization system to help locate and avoid veins to minimize bruising during cosmetic procedures</ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={5} className='our-services-img v-center-col p-3 p-sm-3 p-md-3 p-lg-5'>
                    {/* Background image */}
                </Col>
            </Row>
        </Container>
    )
}

export default Services;