import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image'
import CloseButton from 'react-bootstrap/CloseButton';
import subway from '../assets/logo.svg';
import './Navigation.css';

const BOOK_URL = 'https://app.acuityscheduling.com/schedule.php?owner=18381267';

const Navigation = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    function expandNavbar() {
        setIsExpanded(true);
    }

    function collapseNavbar() {
        setIsExpanded(false);
    }

    function handleBookAppt() {
        window.open(BOOK_URL, '_blank');
    }

    return (
        <Navbar bg='transparent' expand={false} expanded={isExpanded} fixed='top' variant='dark' onSelect={collapseNavbar}>
            <Container fluid className='justify-content-end'>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} onClick={expandNavbar} />
                <Navbar.Offcanvas backdrop={true} bg="dark"
                    id={`offcanvasNavbar-expand-false`}
                    aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                    placement="end"
                    restoreFocus={false}
                >
                    <Offcanvas.Header className='justify-content-end'>
                        <CloseButton variant='white' aria-label='Close' onClick={collapseNavbar}></CloseButton>
                    </Offcanvas.Header>

                    <Offcanvas.Body>
                        <Container fluid className='d-block d-md-none'>
                            <a href='#' onClick={collapseNavbar}>
                                <Image alt='57 West Dermatology subway inspired logo' className="subway-logo py-3 mb-4" src={subway} fluid></Image>
                            </a>
                        </Container>
                        <Nav className="justify-content-end flex-grow-1 px-3">
                            <Nav.Link onClick={handleBookAppt}><strong>Book Appointment</strong></Nav.Link>
                            <Nav.Link href='#practice'>Our Practice</Nav.Link>
                            <Nav.Link href='#services'>Our Services</Nav.Link>
                            <Nav.Link href='#office'>Office Info</Nav.Link>
                            <Nav.Link href='#team'>Meet Our Team</Nav.Link>
                            <Nav.Link href='#testimonials'>Patient Testimonials</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Container>
        </Navbar>
    )
}

export default Navigation;