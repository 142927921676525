import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import alan from '../assets/alan.jpg';
import carrie from '../assets/carrie.jpg';
import './Team.css';

const BOOK_URL = 'https://app.acuityscheduling.com/schedule.php?owner=18381267';

const Team = () => {
    return (
        <Container id='our-team' fluid className='team-container'>
            <Row>
                <Col md={12}>
                    <div className='text-start text-md-center p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h1>Meet Our Team</h1>
                        <p>Our attentive staff is available Monday through Friday to answer your questions and ensure that you are 100% satisfied.</p>
                        <a href={BOOK_URL} target='_blank'>Book your next appointment online now!</a>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={{ span: 5, offset: 1 }}>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <Card>
                            <Card.Img variant='top' src={alan} alt='Headshot of Dr. Alan Glass' />
                            <Card.Body>
                                <Card.Title><strong>Alan T. Glass</strong></Card.Title>
                                <Card.Subtitle>M.D., F.A.A.D.</Card.Subtitle>
                                <br />
                                <Card.Text>
                                    Dr. Glass received a B.A. from Yeshiva University, graduating with honors. He received his Medical Doctorate degree from UMDNJ - Robert Wood Johnson Medical School, completed an internship in Internal Medicine at the Mount Sinai Medical Center in NY, and his Dermatology training at SUNY Health Science Center at Brooklyn, where he served as Chief Clinical Assistant Instructor.
                                    <br /><br />
                                    He has lectured extensively in the field of Dermatology, authored several articles in magazines and has had clinical research articles published in the dermatologic literature. Dr. Glass is Board Certified in Dermatology and specializes in Dermatologic surgery for benign and malignant skin lesions as well as medical and cosmetic laser procedures.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={{ span: 5 }}>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <Card>
                            <Card.Img variant='top' src={carrie} alt='Headshot of Carrie Doolan, RPA-C' />
                            <Card.Body>
                                <Card.Title><strong>Carrie Doolan</strong></Card.Title>
                                <Card.Subtitle>RPA-C</Card.Subtitle>
                                <br />
                                <Card.Text>
                                    Carrie is an NCCPA certified Physician Assistant with over 25 years of clinical experience in Dermatology and Primary Care/Internal Medicine. She completed her medical training at Touro College, graduating Cum Laude. She is a clinical preceptor for various programs where she mentors students during their clinical rotations and has published several articles in the medical literature.
                                    <br /><br />
                                    In addition to her broad knowledge of general Dermatology, she has extensive experience in facial rejuvenation. Carrie is an upstanding member of the Society of Dermatology Physician Assistants and is certified in Botox, Dysport, Xeomin and numerous filler injections, as well as laser treatments, Ultherapy, Microneedling (with and without RF) and medical grade microdermabrasion.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} className='our-team-img'>
                    {/* Background image */}
                </Col>
            </Row>
        </Container>
    )
}

export default Team;