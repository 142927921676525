import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Practice.css';

const Practice = () => {
    return (
        <Container id='our-practice' fluid className='practice-container'>
            <Row>
                <Col xs={12} sm={12} md={5} className='our-practice-img v-center-col p-3 p-sm-3 p-md-3 p-lg-5'>
                    {/* Background image */}
                </Col>
                <Col xs={12} sm={12} md={7} className='practice-col v-center-col'>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h1>Our Practice</h1>
                        <p>Our board certified Dermatologists and Dermatology trained Physician Assistants will give you the attention and personal service you deserve. We offer the latest advances in the diagnosis, treatment and prevention of diseases of the skin, hair and nails. We also offer treatments that can improve the appearance of aging and sun-damaged skin. Our Dermatology practices have been located in New York since 1984. Let us put our experience to work for you!</p>
                        <p>At 57 West Dermatology, we strive to provide our patients with high quality skin care personalized for their unique needs. Our staff members are professional, courteous and efficient.</p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Practice;