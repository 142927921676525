import Navigation from './components/Navigation';
import Home from './components/Home';
import Practice from './components/Practice';
import Services from './components/Services';
import Office from './components/Office';
import Team from './components/Team';

import Testimonials from './components/Testimonials';
import Footer from './components/Footer';

import './App.css';

const App = () => {

  return (
    <div className="App">

      <Navigation></Navigation>
      
      <section id='home'>
        <Home></Home>
      </section>
      
      <section id='practice'>
        <Practice></Practice>
      </section>
      
      <section id='services'>
        <Services></Services>
      </section>

      <section id='office'>
        <Office></Office>
      </section>

      <section id='team'>
        <Team></Team>
      </section>
      
      <section id='testimonials'>
        <Testimonials></Testimonials>
      </section>

      <footer id='footer'>
        <Footer></Footer>
      </footer>
    </div>
  )
}

export default App;
