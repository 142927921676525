import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Testimonials.css';

const Testimonials = () => {
    return (
        <Container className='testimonial-container' fluid>
            <Row>
                <Col md={12} className='p-5'>
                    <h1>Patient Testimonials</h1>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                    <div className='p-3'>
                        <blockquote className='text-start blockquote'>
                            <p>
                                <i className="bi bi-quote me-2"></i>
                                Dr. Glass is the MOST caring professional I know. He is very knowledgable and has great experience in the field of Dermatology. He has long term staff retention which says a lot of him as a person. I, as someone in the healthcare profession, would be proud to refer to Dr. Glass.
                            </p>
                            <footer className='blockquote-footer'>Tina K.</footer>
                        </blockquote>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                    <div className='p-3'>

                        <blockquote className='text-start blockquote'>
                            <p>
                                <i className="bi bi-quote me-2"></i>
                                Carrie's meticulous care and personal touch was a breath of fresh air when it came to treating my skin condition. I can't thank her enough!
                            </p>
                            <footer className='blockquote-footer'>Lauren E.</footer>
                        </blockquote>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                    <div className='p-3'>
                        <blockquote className='text-start blockquote'>
                            <p>
                                <i className="bi bi-quote me-2"></i>
                                Dr Glass was professional, friendly and informative. I highly recommend him. I drove in from Brooklyn to see him!
                            </p>
                            <footer className='blockquote-footer'>Sandra I.</footer>
                        </blockquote>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={6} lg={3}>
                    <div className='p-3'>
                        <blockquote className='text-start blockquote'>
                            <p>
                                <i className="bi bi-quote me-2"></i>
                                Carrie went above and beyond when treating me last minute before traveling abroad. I am eternally grateful for her help and couldn't be happier with the results!
                            </p>
                            <footer className='blockquote-footer'>Michelle S.</footer>
                        </blockquote>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Testimonials;