import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Footer.css';

const Footer = () => {
    return (
        <Container className='footer-container' fluid>
            <Row>
                <Col xs={12} sm={12} md={12} className='py-4'>
                    <p>57 West Dermatology | &copy; 2022</p>
                    <p>Artwork by <a href='https://abigailg.myportfolio.com/' target='_blank' rel="noopener">Abigail Glass</a> | Website by <a href="https://www.linkedin.com/in/gideonglass" target="_blank" rel="noopener">Gideon Glass</a></p>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;