import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import './Office.css';

const Office = () => {
    return (

        <Container id='our-office' fluid className='office-container'>
            <Row>
                <Col md={12} className='p-0'>
                    <iframe title="Map of 57 West Dermatology" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12087.547095610747!2d-73.976723!3d40.764515!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5b206a028784f61!2s57+West+Dermatology!5e0!3m2!1sen!2sus!4v1483373785588" width="100%" height="300" frameBorder="0" style={{ border: 0 }} allowFullScreen></iframe>
                </Col>

                <Col xs={12} sm={12} md={4}>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h3 className='pb-2 hours-header'>Office Hours</h3>
                        <ListGroup>
                            <ListGroup.Item className='office-item'><b>Mon:</b> 8:30 AM - 4:30 PM</ListGroup.Item>
                            <ListGroup.Item className='office-item'><b>Tues:</b> 8:30 AM - 5:30 PM</ListGroup.Item>
                            <ListGroup.Item className='office-item'><b>Wed:</b> 9:00 AM - 5:00 PM</ListGroup.Item>
                            <ListGroup.Item className='office-item'><b>Thur:</b> 8:30 AM - 5:30 PM</ListGroup.Item>
                            <ListGroup.Item className='office-item'><b>Fri:</b> 8:30 AM - 12:00 PM</ListGroup.Item>
                            <ListGroup.Item className='office-item'><b>Sat/Sun</b>: CLOSED</ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={4}>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h3 className='pb-2 contact-header'>Contact Us</h3>
                        <ListGroup>
                            <ListGroup.Item className='office-item'>
                                <b>Phone: </b>
                                <span className='ms-2'>(646) 861-3234</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='office-item'>
                                <b>Fax: </b>
                                <span className='ms-2'>(646) 861-3383</span>
                            </ListGroup.Item>
                            <ListGroup.Item className='office-item'>
                                <b>Email: </b>
                                <span className='ms-2'>
                                    <a href='mailto:derm57@icloud.com?Subject=Contact%20Us'>
                                        derm57@icloud.com
                                    </a>
                                </span>
                            </ListGroup.Item>
                            <ListGroup.Item className='office-item'>
                                <b>Address:</b>
                                <address>
                                    57 W 57th Street<br />
                                    Suite 1012<br />
                                    New York, NY 10019
                                </address>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>


                <Col xs={12} sm={12} md={4}>
                    <div className='text-start p-3 p-sm-3 p-md-3 p-lg-5'>
                        <h3 className='pb-2 connect-header'>Stay Connected</h3>
                        <ListGroup>
                            <ListGroup.Item className='office-item' action href="https://www.facebook.com/57WestDerm" target="_blank" rel="noopener">
                                <i className="bi bi-facebook"></i> Give us a like!
                            </ListGroup.Item>
                            <ListGroup.Item className='office-item' action href="https://www.instagram.com/57westderm/" target="_blank" rel="noopener">
                                <i className="bi bi-instagram"></i> Follow our team @57WestDerm
                            </ListGroup.Item>
                            <ListGroup.Item className='office-item' action href="https://www.instagram.com/carrie_derm_pa/" target="_blank" rel="noopener">
                                <i className="bi bi-instagram"></i> Follow Carrie @carrie_derm_pa
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>

                <Col xs={12} sm={12} md={12} className='our-office-img'>
                    {/* Background image */}
                </Col>
            </Row>
        </Container>
    )
}

export default Office;